* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  scrollbar-gutter: stable;
  font: 16px / 1.5 system-ui, sans-serif;
}

li {
  list-style: none;
}

.layout {
  grid-template: auto 1fr auto / 1fr;
  min-height: 100vh;
  display: grid;
}

.toolbar {
  background-color: #fff;
  border-bottom: 1px solid #dcdcdc;
  grid-template: auto / 5rem repeat(3, 1fr);
  justify-content: space-between;
  align-items: center;
  padding-block: .75rem;
  display: grid;
  position: sticky;
  top: 0;
}

.footer {
  text-align: center;
  background-color: #fff;
  border-top: 1px solid #dcdcdc;
  padding: .75rem;
  position: sticky;
  bottom: 0;
}

.button {
  color: #fff;
  background-color: #1e90ff;
  border: 0;
  border-radius: .25rem;
  align-items: center;
  height: 1.5rem;
  padding-inline: .75rem;
  font-family: inherit;
  font-size: 1em;
  line-height: 1.25;
  display: inline-flex;
}

.flex {
  align-items: center;
  display: flex;
}

.dropzone {
  grid-row: span 2;
  justify-content: center;
  align-items: center;
  display: flex;
}

.dropzone.active {
  color: #1e90ff;
  background-color: #f0f8ff;
}

.dropzone p {
  pointer-events: none;
  font-size: 1.5rem;
  font-weight: bolder;
}

.diff {
  display: block;
}

.diff-row {
  counter-increment: list-item;
  grid-template: auto / 5rem repeat(3, 1fr);
  font: .875rem monospace;
  display: grid;
}

.diff-row:hover {
  background-color: #f5f5f5;
}

.diff-col, .diff-row:before {
  overflow-wrap: break-word;
  white-space: pre-wrap;
  padding: .25rem .75rem;
}

.diff-row:not(:hover) .diff-col {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.diff-row:before {
  color: gray;
  content: counter(list-item);
  text-align: right;
  background-color: #f5f5f5;
}

.diff-row.match-equal {
  color: #696969;
}

.diff-row:not(.match-equal) .diff-col.key {
  color: #000;
  font-weight: bolder;
}

.diff-row.match-different .diff-col.value {
  color: #b8860b;
  background-color: #ffff000d;
}

.diff-row.match-a .diff-col.value-b, .diff-row.match-b .diff-col.value-a {
  color: brown;
  background-color: #ff45000d;
}

.diff-row.match-a .diff-col.value-a, .diff-row.match-b .diff-col.value-b {
  color: green;
  background-color: #9acd320d;
}
/*# sourceMappingURL=index.710e4630.css.map */
