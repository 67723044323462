* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font: 16px/1.5 system-ui, sans-serif;
  scrollbar-gutter: stable;
}

li {
  list-style: none;
}

.layout {
  display: grid;
  grid-template: auto 1fr auto / 1fr;
  min-height: 100vh;
}

.toolbar {
  align-items: center;
  background-color: white;
  border-bottom: 1px solid gainsboro;
  display: grid;
  grid-template: auto / 5rem repeat(3, 1fr);
  position: sticky;
  padding-block: 0.75rem;
  top: 0;
  justify-content: space-between;
}

.footer {
  background-color: white;
  border-top: 1px solid gainsboro;
  bottom: 0;
  padding: 0.75rem;
  position: sticky;
  text-align: center;
}

.button {
  align-items: center;
  background-color: dodgerblue;
  border-radius: 0.25rem;
  border: 0;
  color: white;
  display: flex inline;
  font-family: inherit;
  font-size: 1em;
  height: 1.5rem;
  line-height: 1.25;
  padding-inline: 0.75rem;
}

.flex {
  align-items: center;
  display: flex;
}

.dropzone {
  align-items: center;
  display: flex;
  grid-row: span 2;
  justify-content: center;
}

.dropzone.active {
  background-color: aliceblue;
  color: dodgerblue;
}

.dropzone p {
  font-size: 1.5rem;
  font-weight: bolder;
  pointer-events: none;
}

.diff {
  display: block;
}

.diff-row {
  display: grid;
  grid-template: auto / 5rem repeat(3, 1fr);
  counter-increment: list-item;
  font: 0.875rem monospace;
}

.diff-row:hover {
  background-color: whitesmoke;
}

.diff-col,
.diff-row::before {
  padding: 0.25rem 0.75rem;
  overflow-wrap: break-word;
  white-space: pre-wrap;
}

.diff-row:not(:hover) .diff-col {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.diff-row::before {
  background-color: whitesmoke;
  color: gray;
  content: counter(list-item);
  text-align: right;
}

.diff-row.match-equal {
  color: dimgray;
}

.diff-row:not(.match-equal) .diff-col.key {
  font-weight: bolder;
  color: black;
}

.diff-row.match-different .diff-col.value {
  background-color: color-mix(in srgb, yellow, transparent 95%);
  color: darkgoldenrod;
}

.diff-row.match-a .diff-col.value-b,
.diff-row.match-b .diff-col.value-a {
  background-color: color-mix(in srgb, orangered, transparent 95%);
  color: brown;
}

.diff-row.match-a .diff-col.value-a,
.diff-row.match-b .diff-col.value-b {
  background-color: color-mix(in srgb, yellowgreen, transparent 95%);
  color: green;
}
